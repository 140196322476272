<template>
  <el-form
    :label-position="labelPosition"
    :model="ruleForm"
    :rules="rules"
    ref="ruleForm"
    label-width="120px"
    class="form"
  >
    <el-row :gutter="24">
      <el-col :xl="24">
        <el-form-item label="Фамилия Имя Отчество" prop="fullname">
          <el-input
            v-model="ruleForm.fullname"
            type="text"
            placeholder="Иванов Иван Васильевич"
            name="fullname"
          ></el-input>
        </el-form-item>
      </el-col>

      <el-col :xl="24">
        <el-form-item label="Ваша компания" prop="company">
          <el-input
            v-model="ruleForm.company"
            type="text"
            placeholder="ООО «Пример»"
            name="company"
          ></el-input>
        </el-form-item>
      </el-col>

      <el-col>
        <el-row :gutter="20">
          <el-col :sm="24" :md="12">
            <el-form-item label="Электронная почта" prop="email">
              <el-input
                v-model="ruleForm.email"
                type="email"
                placeholder="ivanov@example.ru"
                name="email"
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :sm="24" :md="12">
            <el-form-item label="Рабочий телефон" prop="phone">
              <el-input
                v-model="ruleForm.phone"
                type="tel"
                placeholder="+7 000 000 00 00"
                name="phone"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-col>

      <template v-if="simple">
        <el-col :xl="24">
          <el-form-item label="Ваш вопрос" prop="comment">
            <el-input
              v-model="ruleForm.comment"
              type="text"
              placeholder=""
              name="comment"
            ></el-input>
          </el-form-item>
        </el-col>
      </template>
      <template v-else>
        <el-col :sm="24" :md="12">
          <el-form-item label="ИНН поставщика" prop="tin">
            <el-input
              v-model="ruleForm.tin"
              type="text"
              placeholder="1234567890"
              name="tin"
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :sm="24" :md="12">
          <el-form-item label="Дни отсрочки" prop="days">
            <el-input
              v-model="ruleForm.days"
              type="text"
              placeholder="100500"
              name="days"
            ></el-input>
          </el-form-item>
        </el-col>
      </template>

      <el-col :xl="24">
        <el-form-item prop="policy" class="policy-container">
          <el-checkbox
            v-model="ruleForm.policy"
            name="policy"
            @change="setCookie"
          ></el-checkbox>
          <p class="policy">
            Я даю согласие на&nbsp;обработку персональных данных
            на&nbsp;условиях
            <a
              href="https://ediweb.com/ru-ru/policies/privacy"
              class="int-link"
            >
              Политики обработки персональных данных
            </a>
          </p>
        </el-form-item>
      </el-col>
      <el-col :xl="24">
        <el-form-item class="actions">
          <el-button type="primary" @click="submitForm('ruleForm')"
            >Отправить</el-button
          >
        </el-form-item>
      </el-col>
    </el-row>
    <input type="hidden" name="ga" :value="ga" v-if="ga" />
    <input type="hidden" name="ym" :value="ym" v-if="ym" />
    <input type="hidden" name="source" :value="source" v-if="source" />
    <input
      v-for="(value, name) in extra"
      :key="name"
      type="hidden"
      :name="name"
      :value="value"
    />
  </el-form>
</template>

<script>
export default {
  name: "v-form",
  props: {
    simple: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      extra: {
        "policy-accepted": false,
      },
      ga: "",
      ym: "",
      source: "",
      labelPosition: "top",
      ruleForm: {
        fullname: "",
        company: "",
        email: "",
        tin: "",
        phone: "",
        days: "",
        comment: "",
        policy: [],
      },
      rules: {
        fullname: [
          {
            required: true,
            message: "Поле должно быть заполнено",
            trigger: "blur",
          },
        ],
        company: [
          {
            required: true,
            message: "Поле должно быть заполнено",
            trigger: "blur",
          },
        ],
        comment: [
          {
            required: true,
            message: "Поле должно быть заполнено",
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: "Поле должно быть заполнено",
            trigger: "blur",
          },
          {
            type: "email",
            message: "Введите корректный email",
            trigger: ["blur", "change"],
          },
        ],
        tin: [
          {
            required: true,
            message: "Поле должно быть заполнено",
            trigger: "blur",
          },
          {
            pattern: /^[0-9]+$/,
            message: "Поле должно содержать числовое значение",
            trigger: "blur",
          },
        ],
        phone: [
          {
            required: true,
            message: "Поле должно быть заполнено",
            trigger: "blur",
          },
        ],
        days: [
          {
            required: true,
            message: "Поле должно быть заполнено",
            trigger: "blur",
          },
          {
            pattern: /^[0-9]+$/,
            message: "Поле должно содержать числовое значение",
            trigger: "blur",
          },
        ],
        policy: [
          {
            required: true,
            message: "Поле должно быть заполнено",
            trigger: "change",
          },
        ],
      },
    };
  },
  methods: {
    submitForm(formName) {
      if (!this.getCookie("privacy-policy")) {
        this.setPolicyCookie();
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // Не в mounted, т.к. данные будут неправильными из-за пререндеринга
          if (
            typeof window.ga !== "undefined" &&
            typeof window.ga.getAll !== "undefined"
          ) {
            this.ga = window.ga.getAll()[0].get("clientId");
          }
          this.ym = this.getYM();
          this.source = document.URL;
          this.$el.submit();
          // this.resetForm(formName);
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    getYM() {
      const match = document.cookie.match("(?:^|;)\\s*_ym_uid=([^;]*)");
      return match ? decodeURIComponent(match[1]) : "";
    },
    getUTMMetrics() {
      const params = document.URL.match(/utm_[a-z]+=([^#&]+)/g) || [];
      const result = {};
      params.forEach((value) => {
        const parts = value.split("=");
        result[parts[0]] = parts[1] || "";
      });
      return result;
    },
    setCookie(data) {
      this.extra["policy-accepted"] = data;
      if (data === true && !this.getCookie("privacy-policy")) {
        this.setPolicyCookie();
      }
    },
    setPolicyCookie() {
      const policy = 15;
      const lifetime = 1000 * 60 * 60 * 24 * 365;
      const expires = new Date(new Date().getTime() + lifetime).toGMTString();
      document.cookie = `privacy-policy=${policy}; expires=${expires}; path=/`;
    },
    getCookie(name) {
      const matches = document.cookie.match(
        new RegExp(
          /* eslint-disable no-useless-escape */
          `(?:^|; )${name.replace(
            /([\.$?*|{}\(\)\[\]\\\/\+^])/g,
            "\\$1"
          )}=([^;]*)`
          /* eslint-enable */
        )
      );
      return !!matches;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/main.scss";

.form {
  .el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:before,
  .el-form-item.is-required:not(.is-no-asterisk)
    .el-form-item__label-wrap
    > .el-form-item__label:before {
    content: none;
  }

  .el-form-item {
    margin-bottom: 16px;

    &__error {
      padding-top: 6px;
      font-size: 14px;
    }

    &.is-error {
      margin-bottom: 32px;
      .el-checkbox {
        ~ .el-form-item__error {
          margin-left: $space-32;
        }
        .el-checkbox__inner {
          border: 1px solid $red-600;
        }
        .el-checkbox__input.is-focus .el-checkbox__inner {
          border: 1px solid $red-600 !important;
        }
      }
    }

    &__label {
      line-height: 1.5;
    }

    &.actions {
      margin-top: $space-24;
    }
  }

  .el-form--label-top .el-form-item__label {
    padding: 0 0 6px 0;
  }

  .policy {
    font-size: $space-16;
    margin: 0 0 0 $space-8;
    &-container {
      margin-top: $space-24;
      > .el-form-item__content {
        display: flex;
        .el-checkbox__input {
          display: block;
        }
      }
    }

    a {
      border-bottom: none;
      color: $green-700;
    }
  }

  &.dark-bg {
    .el-form--label-top .el-form-item__label,
    .el-form-item__label,
    .policy {
      color: $white;
      a {
        border-bottom: 1px solid $white;
        color: $white;
      }
    }

    .el-select-dropdown.el-popper {
      left: 0 !important;
      transform-origin: center top !important;
    }
  }

  @mixin input_placeholder {
    color: $gray-400;
    font-weight: 300;
  }

  .el-input__inner::-moz-placeholder {
    @include input_placeholder;
  }
  .el-input__inner::-webkit-input-placeholder {
    @include input_placeholder;
  }
  .el-input__inner:-ms-input-placeholder {
    @include input_placeholder;
  }
  .el-input__inner::-ms-input-placeholder {
    @include input_placeholder;
  }
  .el-input__inner::placeholder {
    @include input_placeholder;
  }
}
</style>
